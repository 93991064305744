<template>
    <div>
        <h3>Add Absence</h3>

        <form @submit.prevent="add">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Date of Absence</label>
                <DatePicker v-model="absenceDate" :input-attributes="{ class: 'form-control' }" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Reason</label>
                <select class="form-control" v-model="absenceReasonID">
                    <option v-for="[id, reason] in cache.absenceReasonCache.TYPES" v-bind:key="id" :value="id">{{ reason }}</option>
                </select>
            </div>

            <div class="col-xs-12">
                <button class="btn btn-default">Save Absence</button>
            </div>
        </form>
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store";
    import DatePicker from "@/components/utils/DatePicker";
    import absenceReasonCache from "@/cache/absence.cache";

    export default {
        name: "AddAbsence",
        components: {DatePicker},
        data() {
            return {
                cache: {
                    absenceReasonCache
                },
                absenceDate: null,
                absenceReasonID: null
            }
        },
        methods: {
            add: function () {
                let options = {
                    'params[absence_date]': this.absenceDate,
                    'params[absence_reason_id]': this.absenceReasonID,
                    'params[user_id]': store.state.object.id
                };

                store.api('save_absence', options).then(() => {
                    this.$emit('update');
                });

                this.absenceDate = null;
                this.absenceReasonID = null;
            }
        }
    }
</script>

<style scoped>

</style>