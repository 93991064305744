export default {
	TYPES: [
		[281, 'Illness'],
		[282, 'Doctor Appointment'],
		[283, 'Family Emergency'],
		[284, 'Other'],
		[285, 'Tardy'],
		[286, 'No Call No Show'],
		[336, 'Tardy With Call 1 Point'],
		[429, 'Tardy Without Call 2 Points'],
		[337, 'Absence With Call 2 Points'],
		[338, 'No Call No Show 3 Points']
	]
}