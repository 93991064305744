<template>
    <div class="table-responsive spacing_top">
        <table class="table">
            <tbody>
                <tr>
                    <th>Day</th>
                    <th>Time</th>
                    <th class="number">Hours</th>
                </tr>
                <tr v-for="(time, id) in data.user_times" v-bind:key="id">
                    <td
                        :class="(time.alert ? 'bg-danger text-danger' : '') + (isUserAdmin ? ' pointer' : '')"
                        :title="time.alert"
                        @click="showEditShiftDialog(time.day_full)"
                    >
                         {{ time.day }}
                    </td>
                    <td>
                        <span
                            v-for="clock in time.clocks"
                            v-bind:key="clock.user_clock_id"
                            :title="clock.time"
                            :class="('clock_' + clock.type) + (isUserAdmin ? ' pointer' : '')"
                            @click="showEditDateTimeDialog(clock.dt, clock.user_clock_id)"
                        >
                            {{ clock.clock_time }}
                        </span>
                        <span
                            v-for="(additionalClock, id) in time.additional_hours"
                            v-bind:key="id"
                            :class="('clock_hours bg-info') + (isUserAdmin ? ' pointer' : '')"
                            @click="showDeleteAdditionalHoursDialog(additionalClock.type, time.day_full)"
                            :title="time.day_full">
                                {{ additionalClock.hours }}
                                {{ additionalClock.label }}
                        </span>
                    </td>
                    <td class="number">{{ time.reg_hours }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-for="(total, id) in data.user_totals" v-bind:key="id">
                    <td colspan="2" class="number">
                        {{ total.label }}
                    </td>
                    <td
                        :class="(total.alert ? 'bg-danger text-danger' : '') + ' number'"
                        :title="(total.alert ? total.alert : '')"
                    >
                        <strong>{{ total.total }}</strong>
                    </td>
                </tr>
            </tfoot>
        </table>

        <Notebook :notes="data.notes" :hideAdd="true" :paramsToSend="this.getNotesToSend" :userID="appState.user.userID" />

        <div class="spacing_top table-responsive">
            <h3>Absences</h3>
            <AttendanceTable :title="''" ref="attendanceTable" :userID="state.object.id" :start-date="dateHelpers.getLastSunday()" :end-date="dateHelpers.getToday()" />
            <table class="table">
                <tbody>
                <tr>
                    <th>Date</th>
                    <th>Reason</th>
                    <th>Option</th>
                </tr>
                <tr v-for="absence in data.absences" v-bind:key="absence.user_absence_id">
                    <td>{{ absence.absence_date }}</td>
                    <td>{{ absence.absence_reason }}</td>
                    <td>
                        <span @click="confirmDeleteAbsence(absence.user_absence_id)" class="glyphicon glyphicon-trash cdi_link"></span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <ConfirmBox
            v-if="showDeleteAbsence"
            heading="Delete"
            body="Delete absence?"
            @close="deleteAbsence"
        />

        <Modal heading="Edit Shift" @close="closeEditShift" v-if="showEditShift">
            <template v-slot:body>
                <!-- Edit Shift -->
                <form @submit.prevent="editShift">
                    <h3>Add Hours</h3>
                    <p>Make selections in calendar</p>
                    <div class="form-inline">
                        <div class="form-group">
                            <label class="sr-only">Type</label>
                            <select class="form-control" v-model="shiftFields.type.val">
                                <option v-for="[id, type] in addShiftClockTypes" v-bind:key="id" :value="id">
                                    {{ type }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group edit_shift_hours">
                            <label class="sr-only">Amount</label>
                            <input type="text" class="form-control" v-model="shiftFields.hours.val" placeholder="Amount">
                        </div>
                        <div class="form-group">
                            <label class="sr-only">Date</label>
                            <DatePicker v-model="shiftFields.date.val" :input-attributes="{readonly: 'readOnly', class: 'form-control'}" />
                        </div>
                        <div class="form-group">
                            <button class="btn btn-default">
                                Add Hours
                            </button>
                        </div>
                    </div>
                </form>

                <!-- Add clock -->
                <form @submit.prevent="addHours">
                    <h3>Add Clock Time</h3>
                    <p>Make selections in calendar</p>
                    <div class="form-inline">
                        <div class='form-group'>
                            <DatePicker v-model="clockFields.time.val"
                                        :input-attributes="{ readonly: 'readOnly', class: 'form-control'}"
                                        :pickTime="true"
                                        :format="'MM/DD/YYYY hh:mm'"
                            />
                        </div>
                        <div class='form-group'>
                            <button class="btn btn-default">
                                Add Clock Time
                            </button>
                        </div>
                    </div>
                </form>
            </template>
        </Modal>

        <Modal heading="Edit Date/Time" @close="closeEditShiftDateTime" v-if="showEditShiftDateTime">
            <template v-slot:body>
                <!-- Edit Shift -->
                <p>Make selections in calendar</p>
                <div class="form-inline">
                    <div class="form-group">
                        <DatePicker v-model="clockFields.time.val"
                                    :input-attributes="{readonly: 'readonly', class: 'form-control'}"
                                    :pickTime="true"
                                    :format="'MM/DD/YYYY hh:mm'"
                        />
                    </div>
                    <div class="form-group">
                        <button class="btn btn-default" @click="editHours">Save Edit</button>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-default" @click="deleteHours">Delete</button>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-default" @click="editHoursDate(false)">Part of Previous Shift</button>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-default" @click="editHoursDate(true)">Start of New Shift</button>
                    </div>
                </div>
            </template>
        </Modal>

        <Modal heading="Edit Hours" @close="closeDeleteAdditionalHours" v-if="showDeleteAdditionalHours">
            <template v-slot:body>
                <button class="btn btn-default" @click="deleteAdditionalHours">Delete</button>
            </template>
        </Modal>
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js';
    import helpers from "@/store/helpers";
    import timecardCache from '@/cache/timecard.cache.js';
    import Notebook from "@/components/businessObjects/utils/Notebook";
    import appStore from "@/store/App.store";
    import ConfirmBox from '@/components/utils/ConfirmBox';
    import Modal from "@/components/utils/Modal";
    import DatePicker from "@/components/utils/DatePicker";
    import DateHelpers from "@/store/DateHelpers";
    import AttendanceTable from "@/components/tools/TimeClock/AttendanceTable.vue";
    import dateHelpers from "@/store/DateHelpers";

    export default {
        name: "Week",
        components: {
            AttendanceTable,
            DatePicker,
            Modal,
            ConfirmBox,
            Notebook
        },
        props: ["data", "chosenWeek"],
        data() {
            return {
                state: store.state,
                cache: {
                    timecardCache
                },
                appState: appStore.state,
                showDeleteAbsence: false,
                absenceToDelete: null,
                showEditShift: false,
                showEditShiftDateTime: false,
                showDeleteAdditionalHours: false,
                pointsValue: [],
                shiftFields: {
                    type: {val: null, qsField: '[hours_type]'},
                    hours: {val: null, qsField: '[hours_amount]'},
                    date: {val: null, qsField: '[hours_date]'}
                },
                clockFields: {
                    time: {val: null, qsField: '[clock_time]'},
                    userClockID: {val: null, qsField: '[user_clock_id]'}
                },
                additionalHoursFields: {
                    type: {val: null, qsField: '[edit_hours_type]'},
                    date: {val: null, qsField: '[edit_hours_date]'}
                }
            }
        },
        methods: {
            reloadAttendanceTable: function() {
                this.$refs.attendanceTable.load(this.state.object.id);
            },
            deleteAbsence: function (confirm) {
                let options = {'params[user_absence_id]': this.absenceToDelete};
                this.absenceToDelete = null;
                this.showDeleteAbsence = false;

                if (!confirm) {
                    return;
                }

                store.api('delete_absence', options).then(() => this.$emit('update'));
            },
            confirmDeleteAbsence: function (id) {
                this.showDeleteAbsence = true;
                this.absenceToDelete = id;
            },
            showEditShiftDialog: function (dateTime) {
                if (!this.isUserAdmin) {
                    return;
                }

                this.showEditShift = true;
                this.shiftFields.date.val = DateHelpers.dateFormatTimePicker(DateHelpers.getDateTime(dateTime));
                this.clockFields.time.val = DateHelpers.dateFormatTimePicker(DateHelpers.getDateTime(dateTime));
            },
            closeEditShift: function () {
                this.showEditShift = false;
            },
            showEditDateTimeDialog: function (dateTime, clockID) {
                if (!this.isUserAdmin) {
                    return;
                }

                this.showEditShiftDateTime = true;
                this.clockFields.time.val = DateHelpers.dateFormatTimePicker(DateHelpers.getDateTime(dateTime));
                this.clockFields.userClockID.val = clockID;
            },
            closeEditShiftDateTime: function () {
                this.showEditShiftDateTime = false;
                this.clockFields.userClockID.val = null;
            },
            editShift: function () {
                store.api('save_hours', helpers.getQSPairs(this.shiftFields)).then(() => {
                    this.$emit('update');
                    this.closeEditShift();
                });
            },
            addHours: function () {
                store.api('save_time', helpers.getQSPairs(this.clockFields)).then(() => {
                    this.$emit('update');
                    this.closeEditShift();
                });
            },
            editHours: function () {
                store.api('edit_time', helpers.getQSPairs(this.clockFields)).then(() => {
                    this.$emit('update');
                    this.closeEditShiftDateTime();
                });
            },
            deleteHours: function () {
                store.api('del_time', helpers.getQSPairs(this.clockFields)).then(() => {
                    this.$emit('update');
                    this.closeEditShiftDateTime();
                });
            },
            editHoursDate: function (next) {
                let options = helpers.getQSPairs(this.clockFields);

                if (next) {
                    options['params[next]'] = 1
                }

                store.api('edit_shift_date', options).then(() => {
                    this.$emit('update');
                    this.closeEditShiftDateTime();
                });
            },
            showDeleteAdditionalHoursDialog: function (type, date) {
                this.showDeleteAdditionalHours = true;
                this.additionalHoursFields.type.val = type;
                this.additionalHoursFields.date.val = date;
            },
            deleteAdditionalHours: function () {
                store.api('delete_hours', helpers.getQSPairs(this.additionalHoursFields)).then(() => {
                    this.$emit('update');
                    this.closeDeleteAdditionalHours();
                });
            },
            closeDeleteAdditionalHours: function () {
                this.showDeleteAdditionalHours = false;
                this.additionalHoursFields.type.val = null;
                this.additionalHoursFields.date.val = null;
            },
            fetchPoints() {
                let options = {
                    'params[user_id]': store.state.object.id
                };
                store.api('get_points', options)
                    .then(data => {
                        this.pointsValue = data;
                    });
            },
            getFinalCountOrDefault(index) {
                if (this.pointsValue[index] && this.pointsValue[index]['final_count'] !== undefined) {
                    return this.pointsValue[index]['final_count'];
                } else {
                    return 0;
                }
            },
        },
        computed: {
            dateHelpers() {
                return dateHelpers
            },
            getNotesToSend: function () {
                return {
                    'params[user_id]': this.state.object.id,
                    'params[week_ending]': this.chosenWeek
                }
            },
            addShiftClockTypes: function () {
                // Filter out OT & Regular
                return this.cache.timecardCache.TYPES.filter(type => type[0] !== 0 && type[0] !== 5);
            },
            isUserAdmin: function () {
                return appStore.hasRole(this.$cdiVars.CDI_ROLE_ACCOUNTING);
            },
            userId: function () {
                return this.state.object.id;
            },
            calculateTotalFinalCount() {
                let total = 0;
                for (let i = 0; i < this.pointsValue.length; i++) {
                    total += parseInt(this.pointsValue[i]['final_count'], 10);
                }
                return total;
            },
            calculateTotalOfNumbersInReason() {
                // Use a computed property to calculate the total of numbers in "absence_reason"
                let total = 0;
                for (const absence of this.data.absences) {
                    const matches = absence.absence_reason.match(/\d+/g);
                    if (matches) {
                        total += matches.reduce((acc, val) => acc + parseInt(val, 10), 0);
                    }
                }
                return total;
            },
            showPoints() {
                // Check if 2 or 4 exists in the roles array
                return this.roles.includes(2) || this.roles.includes(4);
            },
        },
        watch: {
            userId: function(){
                if (this.userId){
                    this.fetchPoints();
                }
            }
        }
    }
</script>

<style scoped>
    .clock_in, .clock_out, .clock_hours {
        display: inline-block;
        margin-bottom: 5px;
        margin-top: 5px;
        padding: 5px 10px;
    }

    .clock_in {
        padding-right: 5px;
        border-radius: 4px 0 0 4px;
        background-color: #e5e8ed;
    }

    .clock_out {
        margin-right: 10px;
        padding-left: 5px;
        border-radius: 0 4px 4px 0;
        background-color: #e5e8ed;
    }

    @media (min-width: 1199px) {
        .edit_shift_hours {
            width: 125px;
        }
    }
</style>