<template>
    <div>
        <h3>{{ header }}</h3>
        <div v-if="!hideAdd" class="spacing_btm">
            <div class="form-group">
                <textarea v-model="note" class="form-control vertical-resize"></textarea>
            </div>
            <button @click="add" class="btn btn-default">Add note</button>
        </div>

        <table class="table">
            <tbody>
                <tr>
                    <th>Actions</th>
                    <th>Note</th>
                    <th>By</th>
                </tr>
                <tr v-for="note in notes" v-bind:key="note.cdi_note_id">
                    <td>
                        <span v-if="!hideRead" @click="read(note.cdi_note_id)" class="glyphicon glyphicon-ok cdi_link" :class="(note.checked || isUserInChecks(note.checks)) ? 'cdi_hide' : ''" title="check this note"></span>
                        <span v-if="!hideStrikeout" @click="strikeConfirm(note.cdi_note_id)" class="glyphicon glyphicon-ban-circle spacing_left cdi_link" :class="note.strikeout ? 'cdi_hide' : ''" title="strike text out"></span>
                        <ConfirmBox
                            v-if="confirmBox"
                            :heading="'Strikeout note?'"
                            :body="'Are you sure you want to strikeout this note?'"
                            @close="strike"
                        />
                    </td>
                    <td :class="note.strikeout ? 'strikeout' : ''">
                        <span class="note_text">{{ note.note_txt }}</span>
                        <span v-for="checks in note.checks" :key="checks.check_dt">
                            <br>
                            <br>
                            &#10003; {{checks.checked_by}} ({{checks.check_dt}})
                        </span>
                    </td>
                    <td>
                        {{ note.note_user }}
                        {{ note.cdi_note_date }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js';
    import ConfirmBox from "@/components/utils/ConfirmBox";

    export default {
        name: "Notebook",
        components: {
            ConfirmBox
        },
        props: {
            notes: {
                type: Array
            },
            hideStrikeout: {
                type: Boolean,
                default: false
            },
            hideRead: {
                type: Boolean,
                default: false
            },
            hideAdd: {
                type: Boolean,
                default: false
            },
            paramsToSend: {
                type: Object
            },
            userID: {
                type: Number
            },
            onlyEmit: {
                type: Boolean,
                default: false
            },
            header: {
                type: String,
                default: 'Notes'
            }
        },
        data() {
            return {
                note: '',
                state: store.state,
                confirmBox: false,
                noteID: 0
            }
        },
        methods: {
            read: function (noteID) {
                if (this.onlyEmit) {
                    store.api('check_note', {'params': noteID}).then(result => this.$emit('read', result));

                    return
                }

                let params = {'params': noteID};

                if (typeof this.paramsToSend !== 'undefined') {
                    params = {
                        'params[cdi_note_id]': noteID,
                        ...this.paramsToSend
                    }
                }

                store.api('check_note', params, 'object.data');
            },
            strikeConfirm: function (noteID) {
                this.noteID = noteID;
                this.confirmBox = true;
            },
            strike: function (confirm) {
                let params = {'params': this.noteID};

                if (typeof this.paramsToSend !== 'undefined') {
                    params = {
                        'params[cdi_note_id]': this.noteID,
                        ...this.paramsToSend
                    }
                }

                this.confirmBox = 0;

                if (!confirm) {
                    return;
                }

                if (this.onlyEmit) {
                    store.api('strikeout_note', params).then(result => this.$emit('strike', result));
                } else {
                    store.api('strikeout_note', params, 'object.data');
                }

                this.noteID = 0;
            },
            add: function () {
                if (this.onlyEmit) {
                    store.api('add_note', {'params[cdi_note]': this.note}).then(result => this.$emit('add', result));
                } else {
                    store.api('add_note', {'params[cdi_note]': this.note}, 'object.data');
                }

                this.note = '';
            },
            isUserInChecks: function (checks) {
                if (typeof checks === 'undefined' || checks.length === 0) {
                    return false;
                }

                return checks.find(check => parseInt(check.user_id) === this.userID);
            }
        }
    }
</script>

<style scoped>
    span.note_text {
        white-space: pre-line;
    }
</style>