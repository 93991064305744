<template>
    <div>
        <h3>Add Note</h3>

        <form @submit.prevent="add">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Note Applies To</label>
                <DatePicker v-model="noteDate" :input-attributes="{ class: 'form-control' }" />
            </div>
            <div class="col-xs-6 form-group">
                <label>Note</label>
                <textarea v-model="note" class="form-control"></textarea>
            </div>

            <div class="col-xs-12">
                <button class="btn btn-default">Save Note</button>
            </div>
        </form>
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store";
    import DatePicker from "@/components/utils/DatePicker";

    export default {
        name: "AddNote",
        components: {DatePicker},
        data() {
            return {
                noteDate: null,
                note: ''
            }
        },
        methods: {
            add: function () {
                let options = {
                    'params[cdi_note_date]': this.noteDate,
                    'params[cdi_note]': this.note
                };

                store.api('add_note', options).then(() => {
                    this.$emit('update');
                });

                this.noteDate = '';
                this.note = '';
            }
        }
    }
</script>

<style scoped>

</style>