export const timecardStore = {
	state: {
		name: 'timecard',
		title: 'Timecard',
		titlePlural: 'Timecards',
		nav: {
            hideSearch: true,
			newView: false,
			views: [
				{
					view: 'timecard',
					label: 'Timecard'
				},
				{
					view: 'addNote',
					label: 'Add Note'
				},
				{
					view: 'addAbsence',
					label: 'Add Absence'
				},
				{
					view: 'toPDF',
					label: 'PDF'
				},
				{
					view: 'toCSV',
					label: 'CSV'
				},
				{
					view: 'history',
					label: 'History'
				}
			]
		},
		// Too custom of a component to use our normal boilerplate stuff; will be more verbose in the actual component
		search: {},
		object: {
			id: null
		}
	}
}